import {
    Abstract
  } from './Abstract';
  
  export class BWGiroComercial extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/girocomercial', session);
        this.cod_giro_comercial = null;
        this.descripcion = null;
        
    }
  }