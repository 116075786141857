<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Cliente BW">
          <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @refresh="refresh" :actions="['new','save','refresh']" />
          <div class="grid formgrid p-fluid">
            <div class="col-12">
              <TabView>
            <TabPanel header="Informacion General">
              <Fieldset :toggleable="true" legend="Informacion General">
            <div class="p-fluid formgrid grid">
              <FormInputText wrapperClass="field col-4" label="Grupo Comercial" :valid="validate.validations.trade_group" v-model="entity.bw_trade_group" />
              <FormInputText wrapperClass="field col-4" label="Nombre Comercial " :valid="validate.validations.trade_name" v-model="entity.bw_trade_name" />
              <FormInputText wrapperClass="field col-4" label="Nombre" :valid="validate.validations.name" v-model="entity.name" />
              <FormInputText wrapperClass="field col-4" label="RFC" :valid="validate.validations.rfc" v-model="entity.rfc" />
              <FormInputText wrapperClass="field col-4" label="Direccion" :valid="validate.validations.address" v-model="entity.address" />
              <FormInputText wrapperClass="field col-4" label="Codigo Postal" :valid="validate.validations.postal_code" v-model="entity.postal_code" />
              <FormInputText wrapperClass="field col-4" label="Pais" :valid="validate.validations.country" v-model="entity.country" />
              <FormInputText wrapperClass="field col-4" label="Estado" :valid="validate.validations.state" v-model="entity.state" />
              <FormInputText wrapperClass="field col-4" label="Municipio" :valid="validate.validations.municipality" v-model="entity.municipality" />
              <FormInputText wrapperClass="field col-4" label="Colonia" :valid="validate.validations.suburb" v-model="entity.suburb" />
              <FormInputText wrapperClass="field col-4" label="E-mail" :valid="validate.validations.email" v-model="entity.email" />
              <FormInputText wrapperClass="field col-4" label="Telefono" :valid="validate.validations.phone" v-model="entity.phone" />
              <FormDropdownComplex :filterFields="['c_RegimenFiscal', 'Descripcion']" :valid="validate.validations.tax_regime" :labelInOption="'c_RegimenFiscal - Descripcion'" 
              :labelInValue="'c_RegimenFiscal - Descripcion'" :wrapperClass="'field col-4'" label="Regimen Fiscal" v-model="entity.tax_regime" 
              :options="c_RegimenFiscal" optionLabel="c_RegimenFiscal" optionValue="c_RegimenFiscal" />
              <FormDropdownComplex :filterFields="['c_UsoCFDI', 'Descripcion']" :valid="validate.validations.use_cfdi" :labelInOption="'c_UsoCFDI - Descripcion'" 
              :labelInValue="'c_UsoCFDI - Descripcion'" :wrapperClass="'field col-4'" label="Uso CFDI" v-model="entity.use_cfdi" 
              :options="c_UsoCFDI" optionLabel="c_UsoCFDI" optionValue="c_UsoCFDI" />
            </div>
          </Fieldset>
            </TabPanel>
            <TabPanel header="Configuracion">
              <Fieldset :toggleable="true" legend="Configuracion">
                <div class="p-fluid formgrid grid">
                  <FormDropdown label="Base de datos" :wrapperClass="'field col-4'" :options="databases" :optionLabel="'alias'" :optionValue="'name'" 
                  @change="searchCompaniesDatabase" :filter="true" v-model="entity.external_database" :clear="true" @clear="() => { entity.external_database = null; searchCompaniesDatabase(); }" :tooltip="'Ingresar la base de datos del cliente, favor de consultar esta informacion con el area de sistemas'"/>
                  <FormDropdown v-model="entity.external_company" :filter="true" label="Empresa" :wrapperClass="'field col-4'" :options="companies" :optionLabel="'company_name'" :optionValue="'id_company'"></FormDropdown>
                  <FormDropdown v-model="entity.external_branch" :filter="true" label="Sucursal" :wrapperClass="'field col-4'" :options="branchesByCompany" :optionLabel="'branch_name'" :optionValue="'id_branch'"></FormDropdown>
                  <FormInputNumber wrapperClass="field col-4" label="Renta Terminal"  mode="currency" v-model="entity.income_tpv" />
                  <FormInputNumber wrapperClass="field col-4" label="Monto Transaccion"  mode="currency"  v-model="entity.total_amount_tpv_note" />
                  <FormDropdownComplex :filterFields="['cod_giro_comercial', 'descripcion']" :labelInOption="'cod_giro_comercial - descripcion'" 
                  :labelInValue="'cod_giro_comercial - descripcion'" :wrapperClass="'field col-4'" label="Giro Comercial" v-model="entity.bw_commercial_business" 
                  :options="girocomercial" optionLabel="descripcion" optionValue="cod_giro_comercial" />
                  <FormCalendar :dateFormat="'HH:mm:ss'" :hourFormat="'HH:mm:ss'" wrapperClass="field col-3" v-model="entity.bw_transaction_initial_time" :showTime="true" :timeOnly="true" :label="'Hora Inicial de Corte'"  ></FormCalendar>
                  <div class="col-1" style="margin-top:1.8rem;">
                    <Button icon="pi pi-times" @click=" () => {entity.bw_transaction_initial_time = null; entity.bw_transaction_final_time = null;} " severity="danger" aria-label="Cancel" />
                  </div>
                  <FormCalendar :disabled="true" :dateFormat="'HH:mm:ss'" :hourFormat="'HH:mm:ss'" wrapperClass="field col-3" v-model="entity.bw_transaction_final_time"  :showTime="true" :timeOnly="true" :label="'Hora Final de Corte'"  ></FormCalendar>
                </div>
              </Fieldset>
            </TabPanel>
            <TabPanel header="Contabilidad">
              <Fieldset :toggleable="true" legend="Contabilidad">
                <div class="p-fluid formgrid grid">
                  <FormDropdownComplex :filterFields="['id_key', 'name']" :labelInOption="'id_key - name'" 
                  :labelInValue="'id_key - name'" :wrapperClass="'field col-4'" label="Cuenta Contable" v-model="entity.id_accounting_ledger_account" 
                  :options="accounts" optionLabel="id_key" optionValue="id" />
                  <FormInputText :readonly="true" wrapperClass="field col-8" v-model="accounting_ledger_account_name" :label="'Nombre Cuenta Contable'" />
              
                </div>
              </Fieldset>
            </TabPanel>
          </TabView>
            </div>
            <div v-if="!modal" class="col-12">
              <Fieldset :toggleable="true" legend="Resultados">
                <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" :rowdelete="true" :rowedit="true" 
                @edited="edit" @deleted="deleteDialog = true"/>
                <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false" @deleted="confirmDelete" />
              </Fieldset>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputNumber from "../../../../components/general/FormInputNumber.vue";
import FormDropdown from "../../../../components/general/FormDropdown.vue";
import FormDropdownComplex from "../../../../components/general/FormDropdownComplex.vue";
import FormInputText from "../../../../components/general/FormInputText.vue";
import BasicDatatable from "../../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../../components/general/BasicFormToolbar.vue";
import DeleteDialog from "../../../../components/general/DeleteDialog.vue";

import { Customer } from "../../../../models/comercial/Customer";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
  satCatalogo,
} from "../../../../utilities/General";
import Loader from "../../../../components/general/Loader.vue";
import Session from "../../../../mixins/sessionMixin";
import { AccountingLedgerAccount } from '../../../../models/contabilidad/AccountingLedgerAccount';
import { UniverseTransaction } from '../../../../models/custom/business_wallet/BWUniverseTransaction';
import { BWGiroComercial } from '../../../../models/custom/business_wallet/BWGiro_comercial';
import FormCalendar from '../../../../components/general/FormCalendar.vue';


export default {
  mixins: [Session],
  props: {
    modal: null,
    id: null
  },
  data() {
    return {
      branches: [],
      companies: [],
      entity: new Customer(),
      entities: [],
      accounts: [],
      c_RegimenFiscal: [],
      c_UsoCFDI: [],
      deleteDialog: false,
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "rfc" }),
        new Rule({ name: "address" }),
        new Rule({ name: "postal_code" }),
        new Rule({ name: "country" }),
        new Rule({ name: "state" }),
        new Rule({ name: "municipality" }),
        new Rule({ name: "suburb" }),
        new Rule({ name: "email", type: 'email' }),
        new Rule({ name: "phone" }),
        new Rule({ name: "tax_regime" }),
        new Rule({ name: "use_cfdi" })
        // new Rule({ name: "trade_name" }),
        // new Rule({ name: "trade_group" }) ,
      ],
      validate: {
        valid: false,
        validations: {
          name: null,
          rfc: null,
          address: null,
          postal_code: null,
          country: null,
          state: null,
          municipality: null,
          suburb: null,
          email: null,
          phone: null,
          tax_regime: null,
          use_cfdi: null,
          trade_name:null,
          trade_group:null
        },
      },
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("RFC", "rfc"),
        new HeaderGrid("Direccion", "address"),
        new HeaderGrid("CP", "postal_code"),
        new HeaderGrid("Pais", "country"),
        new HeaderGrid("Estado", "state"),
        new HeaderGrid("Municipio", "municipality"),
        new HeaderGrid("Colonia", "suburb"),
        new HeaderGrid("Regimen Fiscal", "tax_regime"),
      ],
      databases: [],
      loading: false,
    };
  },
  components: { FormDropdown,FormInputNumber, FormDropdownComplex, DeleteDialog, Loader, FormInputText, BasicFormToolbar, BasicDatatable, FormCalendar },
  created() {
    this.entity = new Customer(this.session);
  
  },
  async mounted() {
    await this.refresh();
  },   
  methods: {
    async searchCompaniesDatabase(value) {
      this.entity.external_branch = null;
      this.entity.external_company = null;
      this.branches = await new UniverseTransaction(this.session).companyDBInfo(value);

      this.companies = [
          ...new Map(this.branches.map((item) => [item["id_company"], item])).values(),
      ];
      console.log(this.companies)
    },
    openNew() {
      this.entity = new Customer(this.session);
    },
    async save() {
      try {
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }              

        if (this.entity.bw_transaction_initial_time != null)
          this.entity.bw_transaction_initial_time = this.entity.bw_transaction_initial_time.getHours()
          .toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
          + ":" + this.entity.bw_transaction_initial_time.getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) + ":00"//"23:00:00"

        if (this.entity.bw_transaction_final_time != null)
          this.entity.bw_transaction_final_time = this.entity.bw_transaction_final_time.getHours()
          .toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) 
          + ":" + this.entity.bw_transaction_final_time.getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) + ":00"//"23:00:00"

        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', entity);
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra
          this.$emit('save', entity);
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        // this.entity = new Customer(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async edit(entity) {
      this.entity = fillObject(this.entity, entity);
      console.log(this.entity.bw_transaction_initial_time);
      this.entity.bw_transaction_initial_time = this.entity.bw_transaction_initial_time == null ? null : 
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate(), 
      this.entity.bw_transaction_initial_time.split(':')[0], 
      this.entity.bw_transaction_initial_time.split(':')[1]);

      this.entity.bw_transaction_final_time = this.entity.bw_transaction_final_time == null ? null 
      : new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate(), 
      this.entity.bw_transaction_final_time.split(':')[0], 
      this.entity.bw_transaction_final_time.split(':')[1]);

      this.branches = await new UniverseTransaction(this.session).companyDBInfo(this.entity.external_database);
      this.companies = [
          ...new Map(this.branches.map((item) => [item["id_company"], item])).values(),
      ];
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new Customer(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        if (!this.modal) 
          this.entities = await this.entity.all();
        this.c_RegimenFiscal = await new satCatalogo(2);
        this.c_UsoCFDI = await satCatalogo(15);
        this.accounts = await new AccountingLedgerAccount(this.session).all();
          this.girocomercial = await new BWGiroComercial(this.session).all();
        if (this.id) {
          this.entity.id = this.id;
          let entity = await this.entity.retrieve();
          this.entity = fillObject(this.entity, entity);
        }
        this.databases = await new UniverseTransaction(this.session).companyDB();
        console.log(this.databases);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    branchesByCompany() {
      let branches = [{id_branch: 0, branch_name: "TODAS LAS SUCURSALES"}]
      this.branches.filter(x => x.id_company == this.entity.external_company).forEach(x => {
        branches.push(x);
      })
      return branches.length == 1 ? [] : branches;
    },
    accounting_ledger_account_name() {
      return this.entity.id_accounting_ledger_account ? this.accounts.find(x => this.entity.id_accounting_ledger_account == x.id) ? this.accounts.find(x => this.entity.id_accounting_ledger_account == x.id).name : "" : "";
    }
  },
  watch: {
    ["entity.bw_transaction_initial_time"](val) {
      if (Object.prototype.toString.call(val) === '[object Date]') {
        if(val) {
          this.entity.bw_transaction_final_time = new Date(val.getFullYear(), val.getMonth(), val.getDate(), val.getHours(), val.getMinutes() - 1);
          this.entity.bw_transaction_final_time.setTime(this.entity.bw_transaction_final_time.getTime() + 24 * 60 * 60 * 1000);
        }
        else {
          this.entity.bw_transaction_final_time = null;
        }
      }

    }
  }
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

</style>
